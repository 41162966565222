import { AppBar, Box, Tab, Tabs } from '@material-ui/core';
import { CurrencyContext } from 'context/currency';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ACOS, aggregatedOrdersChartData, businessEvaluation, calculateTotal, getFilteredOrders, getVisibleOrders } from '../../common/Utils/orders';
import { FbaFee, Order, VisibleOrder, VisibleOrderMetric } from '../../common/types';

export interface ChartData {
  date: string;
  sumOfTotalRevenue: number;
}

interface OrderChartProps {
  startDate: Date;
  endDate: Date;
  orders: Order[];
  fees: FbaFee[];
  singleMetric?: VisibleOrderMetric;
}

const formatConfig: Record<VisibleOrderMetric | "businessEvaluation" | "acos", 'currency' | 'percentage' | 'number'> = {
  quantity: 'number',
  revenue: 'currency',
  netMargin: 'percentage',
  netProfit: 'currency',
  businessEvaluation: 'currency',
  acos: 'percentage',
  fbaFee: 'currency',
  adsCost: 'currency'
};

const OrderChart: React.FC<OrderChartProps> = ({ startDate, endDate, orders, fees, singleMetric }) => {
  const [data, setData] = useState<ChartData[]>([]);
  const [selectedMetric, setSelectedMetric] = useState<VisibleOrderMetric>(singleMetric || 'quantity');
  const [filteredOrders, setFilteredOrders] = useState<VisibleOrder[]>([]);
  const currencyContext = useContext(CurrencyContext);
  const currency = currencyContext?.currency;
  const [exchangeRate, setExchangeRate] = useState<number>(1);
  const { t } = useTranslation();
  const module = 'modules.orders.';

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
        const data = await response.json();
        setExchangeRate(data.rates.EUR);
      } catch (error) {
        console.error('Failed to fetch exchange rate:', error);
      }
    };
    fetchExchangeRate();
  }, []);

  const [vOrders, setVOrders] = useState<VisibleOrder[]>([]);
  useEffect(() => {
    const newOrders = getVisibleOrders(orders, fees);
    setVOrders(newOrders);
    const newFilteredOrders = getVisibleOrders(getFilteredOrders(orders, startDate, endDate), fees);
    setFilteredOrders(newFilteredOrders);
  }, [startDate, endDate, orders, fees]);

  useEffect(() => {
    const aggregatedData = aggregatedOrdersChartData(filteredOrders, selectedMetric);
    setData(aggregatedData);
  }, [filteredOrders, selectedMetric]);

  const formatValue = (value: number, format: 'currency' | 'percentage' | 'number') => {
    if (format === 'percentage') {
      return `${value.toFixed(2)}%`;
    }
    if (format === 'currency') {
      if (currency.value === 'EUR') {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'EUR',
        }).format(value * exchangeRate);
      } else {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(value);
      }
    }
    return value.toString();
  };

  const renderTabLabel = (metric: VisibleOrderMetric) => {
    const total = calculateTotal(filteredOrders, metric);
    return (
      <>
        {t(module + metric)} <br /> {formatValue(total, formatConfig[metric])}
      </>
    );
  };

  // Calculate business evaluation
  const businessEval = businessEvaluation(vOrders);

  const formatTooltip = (value: number) => {
    return [formatValue(value, formatConfig[selectedMetric]), t(module + selectedMetric)];
  };

  return (
    <>
      <Box mb={2}>
        <AppBar position="static" color="default" elevation={0}>
          <Tabs
            value={singleMetric ? singleMetric : selectedMetric}
            onChange={(event, newValue) => setSelectedMetric(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab style={(singleMetric && singleMetric !== 'quantity') ? { display: 'none' } : undefined} value="quantity" label={renderTabLabel('quantity')} />
            <Tab style={(singleMetric && singleMetric !== 'revenue') ? { display: 'none' } : undefined} value="revenue" label={renderTabLabel('revenue')} />
            <Tab style={(singleMetric && singleMetric !== 'netMargin') ? { display: 'none' } : undefined} value="netMargin" label={renderTabLabel('netMargin')} />
            <Tab style={(singleMetric && singleMetric !== 'netProfit') ? { display: 'none' } : undefined} value="netProfit" label={renderTabLabel('netProfit')} />
            <Tab style={singleMetric ? { display: 'none' } : undefined} disabled value="businessEvaluation" label={<>{t(module + 'businessEvaluation')} <br /> {formatValue(businessEval, 'currency')}</>} />
            <Tab style={singleMetric ? { display: 'none' } : undefined} disabled value="acos" label={<>ACOS <br /> {formatValue(ACOS, 'percentage')}</>} />
          </Tabs>
        </AppBar>
      </Box>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip formatter={formatTooltip} />
          <Legend />
          <Line type="linear" dataKey="sumOfTotalRevenue" name={t(module + selectedMetric)} stroke="#009390" />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default OrderChart;
